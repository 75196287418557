import React, {useEffect, useState} from "react";

/** material ui **/
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';

/** icon **/
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

/** components **/
import UserTableToolbar from './UserTableToolbar';
import UserAddDialog from '../UserDialog/UserAddDialog';
import UserUpdateDialog from '../UserDialog/UserUpdateDialog';
import UserDeleteDialog from '../UserDialog/UserDeleteDialog';

/** define **/
import Axios from "_define/Axios";

export default function UserTable(props) {
    const {
        uploading,
        onUploading,
        onAlert,
    } = props;

    const [loaded, setLoaded] = useState(false);
    const [loadedError, setLoadedError] = useState(false);

    const [openAddDialog, setOpenAddDialog] = React.useState(false);
    const [openUpdateDialog, setOpenUpdateDialog] = React.useState(false);
    const [openRemoveDialog, setOpenRemoveDialog] = React.useState(false);
    const [selectedRow, setSelectedRow] = React.useState(null);

    const [rows, setRows] = useState([]);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onLoad = () => {
        if (!loaded && loadedError && uploading) {
            return;
        }

        setLoaded(false);
        onUploading(true);

        let cancel = false;
        Axios.get(`/api/auth/userList`).then(({data}) => {
            const _data = data.data;
            setRows(_data);

            setLoaded(true);
            onUploading(false);
        }).catch((error) => {
            cancel = Axios.isCancel(error);
            if (!cancel) {
                setLoadedError(true);
                onUploading(false);
            }
        });
    };

    useEffect(() => {
        onLoad();
    }, []);

    const handleToggleDialog = (type, isOpen, row) => {
        if (type === "add") {
            setOpenAddDialog(isOpen);
        } else if (type === "update") {
            setOpenUpdateDialog(isOpen);
            setSelectedRow(row);
        } else if (type === "remove") {
            setOpenRemoveDialog(isOpen);
            setSelectedRow(row);
        }

        /** check **/
        if(!isOpen){
            setLoaded(false);
            onLoad();
        }
    };

    return (
        <>
            <UserTableToolbar onOpenDialog={() => {
                handleToggleDialog("add", true, null)
            }}/>
            <TableContainer component={Paper}>
                <Table size={'medium'}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">아이디</TableCell>
                            <TableCell align="center">이름</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            rows.map((row) => {
                                let id = row['id'],
                                    name = row['name'];

                                return (
                                    <TableRow key={id}>
                                        <TableCell component="th" scope="row" align="center">
                                            {id}
                                        </TableCell>
                                        <TableCell component="th" scope="row" align="center">
                                            {name}
                                        </TableCell>
                                        <TableCell align="center">
                                            <IconButton onClick={() => {
                                                handleToggleDialog("update", true, row)
                                            }}>
                                                <EditIcon fontSize="small"/>
                                            </IconButton>

                                            <IconButton onClick={() => {
                                                handleToggleDialog("remove", true, row)
                                            }}>
                                                <DeleteIcon fontSize="small"/>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />

            <UserAddDialog open={openAddDialog}
                           uploading={uploading}
                           onAlert={onAlert}
                           onUploading={onUploading}
                           onClose={() => {
                               handleToggleDialog("add", false, null)
                           }}/>

            <UserUpdateDialog open={openUpdateDialog}
                              id={selectedRow && selectedRow['id'] ? selectedRow['id'] : ""}
                              name={selectedRow && selectedRow['name'] ? selectedRow['name'] : ""}
                              type={selectedRow && selectedRow['auth_type'] ? selectedRow['auth_type'] : ""}
                              uploading={uploading}
                              onAlert={onAlert}
                              onUploading={onUploading}
                              onClose={() => {
                                  handleToggleDialog("update", false, null)
                              }}/>

            <UserDeleteDialog open={openRemoveDialog}
                              id={selectedRow && selectedRow['id'] ? selectedRow['id'] : ""}
                              uploading={uploading}
                              onAlert={onAlert}
                              onUploading={onUploading}
                              onClose={() => {
                                  handleToggleDialog("remove", false, null)
                              }}/>
        </>
    );
};
