import axios from 'axios';
import Cookies from 'js-cookie';

/** config **/
import Config from '../_define/Config'

axios.defaults.baseURL = Config.SERVER_API;
axios.interceptors.request.use(async function (config) {
    const data = Cookies.getJSON('user');

    const token = data && data.token ? data.token : null;
    if(config.url !== "/api/auth/login" && !token){
        throw new axios.Cancel('');
    }

    config.headers['x-access-token'] = Cookies.get('user');
    return config;
}, function (error) {
    return Promise.reject({
        code: Config.SERVER_ERROR_CODE.NOT_LOGGED,
        message: "로그인이 필요합니다."
    });
});

axios.interceptors.response.use(function (response) {
    const responseData = response.data;
    console.log('responseData ', responseData, responseData.status === "success");
    if (responseData.status === "success") {
        return response;
    } else {
        const error = responseData.error,
            {code} = error;

        if (code === Config.SERVER_ERROR_CODE.WORKING_GROUP) {
            return response;
        }

        let message = "서버 에러가 발생 했습니다.";
        switch (code) {
            /** auth **/
            case Config.SERVER_ERROR_CODE.NOT_LOGGED :
                Cookies.remove('user', {path: '/'});
                message = "로그인이 필요합니다.";
                break;
            case Config.SERVER_ERROR_CODE.LOGIN_ERROR :
                message = "로그인에 실패 하였습니다.";
                break;

            /** parameter **/
            case Config.SERVER_ERROR_CODE.PARAM :
                message = "파라미터가 유효하지 않습니다.";
                break;
            case Config.SERVER_ERROR_CODE.PARAM_FILE :
                message = "파라미터에 파일이 없습니다.";
                break;
            case Config.SERVER_ERROR_CODE.PARAM_NOT_MATCH_EXT :
                message = "파일의 확장자가 일치 하지 않습니다.";
                break;
            case Config.SERVER_ERROR_CODE.WORKING_GROUP :
                message = "문서 작업 중 입니다. 작업 완료 후 다시 시도하세요.";
                break;
            case Config.SERVER_ERROR_CODE.WORKING_DOC :
                message = "문서 작업 중 입니다. 작업 완료 후 다시 시도하세요.";
                break;
            case Config.SERVER_ERROR_CODE.ALREADY_GROUP_ID :
                message = "이미 존재 하는 문서 아이디 입니다.";
                break;
            case Config.SERVER_ERROR_CODE.ALREADY_DOC_ID :
                message = "이미 존재 하는 문서 코드 입니다.";
                break;
            case Config.SERVER_ERROR_CODE.NOT_FOUND_GROUP_ID :
                message = "문서 아이디를 찾을 수 없습니다.";
                break;
            case Config.SERVER_ERROR_CODE.NOT_FOUND_DOC_ID :
                message = "문서 코드를 찾을 수 없습니다.";
                break;
            case Config.SERVER_ERROR_CODE.NOT_FOUND_VERSION_ID :
                message = "문서 버전을 찾을 수 없습니다.";
                break;
            case Config.SERVER_ERROR_CODE.NOT_FOUND_FILE :
                message = "파일을 찾을 수 없습니다.";
                break;
            case Config.SERVER_ERROR_CODE.ERROR_WORK_GROUP :
                message = "문서 작업에 에러가 발생하였습니다.";
                break;

            /** database **/
            case Config.SERVER_ERROR_CODE.DATABASE_GROUP_SELECT :
            case Config.SERVER_ERROR_CODE.DATABASE_GROUP_INSERT :
            case Config.SERVER_ERROR_CODE.DATABASE_GROUP_UPDATE :
            case Config.SERVER_ERROR_CODE.DATABASE_GROUP_DELETE :
            case Config.SERVER_ERROR_CODE.DATABASE_DOC_SELECT :
            case Config.SERVER_ERROR_CODE.DATABASE_DOC_INSERT :
            case Config.SERVER_ERROR_CODE.DATABASE_DOC_UPDATE :
            case Config.SERVER_ERROR_CODE.DATABASE_DOC_DELETE :
            case Config.SERVER_ERROR_CODE.DATABASE_VIEWER_SELECT :
            case Config.SERVER_ERROR_CODE.DATABASE_VIEWER_INSERT :
            case Config.SERVER_ERROR_CODE.DATABASE_VIEWER_UPDATE :
            case Config.SERVER_ERROR_CODE.DATABASE_VIEWER_DELETE :
            case Config.SERVER_ERROR_CODE.DATABASE_VIEWER_TEMP_SELECT :
            case Config.SERVER_ERROR_CODE.DATABASE_VIEWER_TEMP_INSERT :
            case Config.SERVER_ERROR_CODE.DATABASE_VIEWER_TEMP_UPDATE :
            case Config.SERVER_ERROR_CODE.DATABASE_VIEWER_TEMP_DELETE :
            case Config.SERVER_ERROR_CODE.DATABASE_VERSION_SELECT :
            case Config.SERVER_ERROR_CODE.DATABASE_VERSION_INSERT :
            case Config.SERVER_ERROR_CODE.DATABASE_VERSION_UPDATE :
            case Config.SERVER_ERROR_CODE.DATABASE_VERSION_DELETE :
            case Config.SERVER_ERROR_CODE.DATABASE_SETTING_SELECT :
            case Config.SERVER_ERROR_CODE.DATABASE_SETTING_INSERT :
            case Config.SERVER_ERROR_CODE.DATABASE_SETTING_UPDATE :
            case Config.SERVER_ERROR_CODE.DATABASE_SETTING_DELETE :
            /** system **/
            case Config.SERVER_ERROR_CODE.SYSTEM_FILE_IO :
            /** convert **/
            case Config.SERVER_ERROR_CODE.CONVERT_MODULE :
                message = "서버 에러가 발생 했습니다.";
                break;
            default:
                message = "서버 에러가 발생 했습니다.";
        }


        return Promise.reject({
            code: code,
            message: message
        });
    }

}, async function (error) {
    return Promise.reject(error);
});

export default axios;
