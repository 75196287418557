import React, {useRef, useState} from 'react';

/** libs **/
import axios from "axios";
import {useHistory} from "react-router-dom";

/** material **/
import {makeStyles} from '@material-ui/core/styles';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";

/** Config **/
import Config from "_define/Config";

/** components **/
import {UploadButton} from 'components/Common';

const useStyles = makeStyles((theme) => ({
    card: {
        maxWidth: 345,
        margin: `${theme.spacing(3)}px auto`,
        boxShadow: "none"
    },

    content: {
        padding: theme.spacing(2, 0.5)
    },

    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(2),
    },

    formControl: {
        margin: theme.spacing(1, 0),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },

    action: {
        display: "flex",
        justifyContent: "flex-end",
        margin: theme.spacing(2),
    },

    button: {
        padding: theme.spacing(1),
    }
}));

export default function MultiUpload({settings, selectId}) {
    const classes = useStyles();
    const history = useHistory();

    const csvFileInput = useRef(null);
    const zipFileInput = useRef(null);

    const [groupId, setGroupId] = useState("");
    const [groupName, setGroupName] = useState("");

    const [csvFile, setCsvFile] = useState(null);
    const [csvFileName, setCsvFileName] = useState("");
    const [zipFile, setZipFile] = useState(null);
    const [zipFileName, setZipFileName] = useState("");

    const [isCheckId, setIsCheckId] = useState(false);
    const [checkCsvFile, setCheckCsvFile] = useState(false);

    const [snackOpen, setSnackOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState({
        groupId: false,
        groupName: false,
        csvFile: false,
        zipFile: false
    });

    const [errorText, setErrorText] = useState({
        groupId: " ",
        groupName: " ",
        csvFile: " ",
        zipFile: " "
    });

    const handleChangeGroupId = (event) => {
        setGroupId(event.target.value);
    };

    const handleChangeGroupName = (event) => {
        setGroupName(event.target.value);
    };

    const handleCsvBrowser = () => {
        csvFileInput.current.click();
    };

    const handleZipBrowser = () => {
        zipFileInput.current.click();
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackOpen(false);
    };

    const initError = () => {
        setError({
            groupId: false,
            groupName: false,
            csvFile: false,
            zipFile: false
        });

        setErrorText({
            groupId: " ",
            groupName: " ",
            csvFile: " ",
            zipFile: " "
        });

        setSnackMessage("");
    };

    const handleCsvFileChange = (event) => {
        event.stopPropagation();
        event.preventDefault();

        let file = event.target.files[0];
        if (file) {
            /** validate check **/
            const supportFormats = ["csv"];
            const file_extension = file.name.split(".").pop().toLowerCase();

            let isValid = false;
            for (let i = 0; i <= supportFormats.length; i++) {
                if (supportFormats[i] === file_extension) {
                    isValid = true;
                }
            }

            if (!isValid) {
                setError({...error, csvFile: true});
                setErrorText({...errorText, csvFile: "지원 하지 않는 문서 포맷 입니다."});
                return;
            }

            initError();
            setCsvFileName(file && file.name ? file.name : "");
            setCsvFile(file);
        }
    };

    const handleZipFileChange = (event) => {
        event.stopPropagation();
        event.preventDefault();

        let file = event.target.files[0];
        if (file) {
            /** validate check **/
            const supportFormats = ["zip"];
            const file_extension = file.name.split(".").pop().toLowerCase();

            let isValid = false;
            for (let i = 0; i <= supportFormats.length; i++) {
                if (supportFormats[i] === file_extension) {
                    isValid = true;
                }
            }

            if (!isValid) {
                setError({...error, zipFile: true});
                setErrorText({...errorText, zipFile: "지원 하지 않는 문서 포맷 입니다."});
                return;
            }

            initError();
            setZipFileName(file && file.name ? file.name : "");
            setZipFile(file);
        }
    };


    /** api **/
    const handleCheckId = (event) => {
        event.stopPropagation();
        event.preventDefault();

        if (uploading) {
            return;
        }

        if (!groupId || groupId.trim().length === 0) {
            setError({...error, groupId: true});
            setErrorText({...errorText, groupId: "약관 아이디를 입력하세요."});
            return;
        }

        if (!groupName || groupName.trim().length === 0) {
            setError({...error, groupName: true});
            setErrorText({...errorText, groupName: "약관 이름을 입력하세요."});
            return;
        }

        initError();
        setUploading(true);
        axios.post(`${Config.SERVER_API}/api/group/check`, {
            groupId: groupId
        }).then((response) => {
            setUploading(false);
            if (response.data) {
                const data = response.data;
                if (data.status === "success") {
                    setIsCheckId(true);
                    setSnackOpen(true);
                    setSnackMessage("아이디 사용이 가능합니다.");
                } else if (data.status === "error") {
                    const {error} = data;
                    if (error.code === Config.SERVER_ERROR_CODE.PARAM) {
                        setSnackOpen(true);
                        setSnackMessage("아이디 이미 존재합니다.");
                    } else {
                        setSnackOpen(true);
                        setSnackMessage("서버 에러가 발생 하였습니다.");
                    }
                }
            }
        }, () => {
            setSnackOpen(true);
            setSnackMessage("서버 에러가 발생 하였습니다.");
            setUploading(false);
        });
    };

    const onCheck = (event) => {
        event.stopPropagation();
        event.preventDefault();

        if (uploading) {
            return;
        }

        if (!csvFile) {
            setError({...error, csvFile: true});
            setErrorText({...errorText, csvFile: "선택된 파일이 없습니다."});
            return;
        }

        const formData = new FormData();
        formData.append("csvFile", csvFile);

        initError();
        setUploading(true);
        axios.post(`${Config.SERVER_API}/api/doc/csvCheck`, formData).then((response) => {
            setUploading(false);
            if (response.data) {
                const data = response.data;
                if (data.status === "success") {
                    setCheckCsvFile(true);
                    setSnackOpen(true);
                    setSnackMessage("중복 된 코드가 없습니다.");
                } else if (data.status === "error") {
                    const {error} = data;
                    if (error.code === Config.SERVER_ERROR_CODE.PARAM) {
                        setSnackOpen(true);
                        setSnackMessage("파라미터를 확인해 주세요.");
                    } else {
                        setSnackOpen(true);
                        setSnackMessage("서버 에러가 발생 하였습니다.");
                    }
                }
            }
        }, () => {
            setSnackOpen(true);
            setSnackMessage("서버 에러가 발생 하였습니다.");
            setUploading(false);
        });
    };

    const onUpload = (event) => {
        event.stopPropagation();
        event.preventDefault();

        if (uploading) {
            return;
        }

        if (!groupId || groupId.trim().length === 0) {
            setError({...error, groupId: true});
            setErrorText({...errorText, groupId: "약관 아이디를 입력하세요."});
            return;
        }

        if (!groupName || groupName.trim().length === 0) {
            setError({...error, groupName: true});
            setErrorText({...errorText, groupName: "약관 이름을 입력하세요."});
            return;
        }

        if (!csvFile) {
            setError({...error, csvFile: true});
            setErrorText({...errorText, csvFile: "선택된 파일이 없습니다."});
            return;
        }

        if (!zipFile) {
            setError({...error, zipFile: true});
            setErrorText({...errorText, zipFile: "선택된 파일이 없습니다."});
            return;
        }

        const formData = new FormData();
        formData.append("groupId", groupId.normalize());
        formData.append("groupName", groupName.normalize());
        formData.append("csvFile", csvFile);
        formData.append("zipFile", zipFile);

        setUploading(true);
        axios.post(`${Config.SERVER_API}/api/doc/multi`, formData).then((response) => {
            setUploading(false);

            if (response.data) {
                const data = response.data;
                if (data.status === "success") {
                    history.push(`/admin/management?id=${groupId}`);
                } else {
                    const error = data.error
                        , {code, message} = error;

                    if (code === Config.SERVER_ERROR_CODE.PARAM) {
                        setSnackOpen(true);
                        setSnackMessage("파라미터를 확인해 주세요.");
                    } else if (code === Config.SERVER_ERROR_CODE.NOT_MATCH_FILE) {
                        setSnackOpen(true);
                        setSnackMessage("파일의 확장자를 확인해 주세요.");
                    } else if (code === Config.SERVER_ERROR_CODE.ALREADY_ID) {
                        setSnackOpen(true);
                        setSnackMessage("약관 아이디가 이미 존재 합니다.");
                    } else {
                        setSnackOpen(true);
                        setSnackMessage("서버 에러가 발생 하였습니다.");
                    }
                }
            }
        }, (error) => {
            setSnackOpen(true);
            setSnackMessage("서버 에러가 발생 하였습니다.");
        });
    };

    return (
        <>
            <Card className={classes.card}>
                <CardContent className={classes.content}>

                    <UploadButton inputRef={csvFileInput}
                                  buttonTitle=".csv 파일을 선택하세요."
                                  previewName={csvFileName}
                                  onClick={handleCsvBrowser}
                                  onInputChange={handleCsvFileChange}
                                  error={error.csvFile}
                                  errorText={errorText.csvFile}/>

                    <UploadButton inputRef={zipFileInput}
                                  buttonTitle="zip 파일을 선택하세요."
                                  previewName={zipFileName}
                                  onClick={handleZipBrowser}
                                  onInputChange={handleZipFileChange}
                                  error={error.zipFile}
                                  errorText={errorText.zipFile}/>
                </CardContent>
                <CardActions className={classes.action}>
                    <Button className={classes.button}
                            color="primary"
                            variant="outlined"
                            disabled={!isCheckId}
                            onClick={onCheck}>
                        CSV 파일 체크
                    </Button>

                    <Button className={classes.button}
                            color="primary"
                            variant="outlined"
                            disabled={!checkCsvFile}
                            onClick={onUpload}>
                        문서 업로드
                    </Button>
                </CardActions>
            </Card>

            <Snackbar
                anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                autoHideDuration={1500}
                open={snackOpen}
                onClose={handleCloseSnackBar}>
                <SnackbarContent className={classes.snack} message={snackMessage}/>
            </Snackbar>

            <div className={classes.loading} style={{display: uploading ? "flex" : "none"}}>
                <CircularProgress/>
            </div>
        </>

    );
}