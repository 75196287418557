import React, {useEffect, useState} from 'react';

/** libs **/
import {connect} from "react-redux";

/** material **/
import {makeStyles} from '@material-ui/core/styles';

/** components **/
import {DashboardViewer} from "components";

const useStyles = makeStyles((theme) => ({
    toolbar: {
        zIndex: 0,
    },

    content: {
        padding: theme.spacing(2)
    }
}));

const Info = ({dispatch, uploading}) => {
    const classes = useStyles();

    return (
        <div className={classes.content}>
            <DashboardViewer dispatch={dispatch} uploading={uploading}/>
        </div>
    );
}


const mapStateToProps = ({auth}) => ({...auth});
export default connect(mapStateToProps)(Info);

