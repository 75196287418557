import React, {useEffect, useState} from 'react';

/** material **/
import {makeStyles} from '@material-ui/core/styles';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";

/** define **/
import Axios from "_define/Axios";

const useStyles = makeStyles((theme) => ({
    card: {
        maxWidth: 345,
        margin: `${theme.spacing(3)}px auto`,
        boxShadow: "none"
    },

    content: {
        padding: theme.spacing(2, 0.5)
    },

    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(2),
    },

    formControl: {
        margin: theme.spacing(1, 0),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },

    action: {
        display: "flex",
        justifyContent: "flex-end",
        margin: theme.spacing(2),
    },

    button: {
        padding: theme.spacing(1),
    }
}));

export default function SchedulerSettings(props) {
    const classes = useStyles();
    const {
        isSchedulerLog,
        schedulerLogCycle,
        isSchedulerView,
        schedulerViewCycle,
        schedulerViewDay,
        onChange,
        onUpdate
    } = props;

    const handleChangeIsSchedulerLog = (event) => {
        onChange(event, "isSchedulerLog");
    };

    const handleChangeSchedulerLogCycle = (event) => {
        onChange(event, "schedulerLogCycle");
    };

    const handleChangeIsSchedulerView = (event) => {
        onChange(event, "isSchedulerView");
    };

    const handleChangeSchedulerViewCycle = (event) => {
        onChange(event, "schedulerViewCycle");
    };

    const handleChangeSchedulerViewDay = (event) => {
        onChange(event, "schedulerViewDay");
    };

    const handleUpdateLog = () => {
        onUpdate();
    };

    const handleUpdateDoc = () => {
        onUpdate();
    };

    return (
        <>
            <Card className={classes.card}>
                <CardContent className={classes.content}>
                    <form className={classes.form} noValidate>
                        <FormControl className={classes.formControl} fullWidth>
                            <InputLabel>뷰어 로그 정보 스케쥴러</InputLabel>
                            <Select
                                value={isSchedulerLog}
                                onChange={handleChangeIsSchedulerLog}>
                                <MenuItem value="0">중지</MenuItem>
                                <MenuItem value="1">실행</MenuItem>
                            </Select>
                            <FormHelperText>열람 및 다운로드 일별 기록을 위한 스케쥴러 주기 설정</FormHelperText>
                        </FormControl>

                        <FormControl className={classes.formControl} fullWidth>
                            <InputLabel>실행 시간</InputLabel>
                            <Input value={schedulerLogCycle}
                                   onChange={handleChangeSchedulerLogCycle}/>
                            <FormHelperText>예) 10 0 * * *</FormHelperText>
                        </FormControl>
                    </form>
                </CardContent>

                <CardActions className={classes.action}>
                    <Button className={classes.button}
                            color="primary"
                            variant="outlined"
                            onClick={handleUpdateLog}>
                        적용
                    </Button>
                </CardActions>
            </Card>

            <Divider/>

            <Card className={classes.card}>
                <CardContent className={classes.content}>
                    <form className={classes.form} noValidate>
                        <FormControl className={classes.formControl} fullWidth>
                            <InputLabel>문서 삭제 스케쥴러</InputLabel>
                            <Select
                                value={isSchedulerView}
                                onChange={handleChangeIsSchedulerView}>
                                <MenuItem value="0">중지</MenuItem>
                                <MenuItem value="1">실행</MenuItem>
                            </Select>
                            <FormHelperText>문서 삭제를 위한 스케쥴러 주기 설정</FormHelperText>
                        </FormControl>

                        <FormControl className={classes.formControl} fullWidth>
                            <InputLabel>유지 기간</InputLabel>
                            <Input value={schedulerViewDay} onChange={handleChangeSchedulerViewDay}/>
                            <FormHelperText>삭제 문서 유지 기간 (일 기준)</FormHelperText>
                        </FormControl>

                        <FormControl className={classes.formControl} fullWidth>
                            <InputLabel>실행 시간</InputLabel>
                            <Input value={schedulerViewCycle}
                                   onChange={handleChangeSchedulerViewCycle}/>
                            <FormHelperText>예) 10 0 * * *</FormHelperText>
                        </FormControl>
                    </form>
                </CardContent>

                <CardActions className={classes.action}>
                    <Button className={classes.button}
                            color="primary"
                            variant="outlined"
                            onClick={handleUpdateDoc}>
                        적용
                    </Button>
                </CardActions>
            </Card>
        </>
    );
}