import React, {useState} from 'react';

/** libs **/
import {connect} from "react-redux";

/** material **/
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import CircularProgress from "@material-ui/core/CircularProgress";

/** icons **/
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import IconButton from '@material-ui/core/IconButton';

/** components **/
import {Navigator} from "components/Views";
import Cookies from "js-cookie";

const drawerWidth = 256;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        minHeight: '100vh',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    app: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    main: {
        flex: 1,
        padding: theme.spacing(6, 4),
        background: theme.palette.background.default,
    },

    footer: {
        padding: theme.spacing(2),
        background: theme.palette.background.default,
    },

    snack: {
        background: theme.palette.primary.main
    },

    loading: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 9999,
        position: "fixed",
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.15)'
    }
}));

const MainView = ({viewId, title, children}) => {
    const classes = useStyles();
    const [uploading, setUploading] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlert(false);
    };

    const handleAlert = (message) => {
        setOpenAlert(true);
        setAlertMessage(message);
    };

    const handleUploading = (isUploading) => {
        setUploading(isUploading);
    };


    const handleLogout = () => {
        Cookies.remove('user', {path: '/'});
        window.location.href = "/";
    };


    return (
        <>
            <div className={classes.root}>
                <nav className={classes.drawer}>
                    <Navigator PaperProps={{style: {width: drawerWidth}}} selectId={viewId}/>
                </nav>
                <div className={classes.app}>
                    <AppBar
                        component="div"
                        className={classes.header}
                        color="primary"
                        position="static"
                        elevation={0}>

                        <Toolbar>
                            <Grid container alignItems="center" spacing={1}>
                                <Grid item xs>
                                    <Typography color="inherit" variant="h5" component="h1">
                                        {title}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Tooltip title="로그아웃">
                                        <IconButton color="inherit"
                                                    className={classes.avatar}
                                                    onClick={handleLogout}>
                                            <ExitToAppIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>

                    {React.cloneElement(children, {
                        uploading: uploading,
                        onUploading: handleUploading,
                        onAlert: handleAlert
                    })}

                </div>
            </div>

            <div className={classes.loading} style={{display: uploading ? "flex" : "none"}}>
                <CircularProgress/>
            </div>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={openAlert}
                autoHideDuration={1000}
                onClose={handleCloseSnackBar}>
                <SnackbarContent
                    classes={{
                        root: classes.snackBar
                    }}
                    message={alertMessage}
                />
            </Snackbar>
        </>
    );
}

const mapStateToProps = ({auth}) => ({...auth});
export default connect(mapStateToProps)(MainView);
