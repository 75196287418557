import React from "react";

/** material ui **/
import {useTheme} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';

/** icon **/
import SettingsIcon from '@material-ui/icons/Settings';
import Visibility from '@material-ui/icons/Visibility';

/** components **/
import DocSettingsDialog from '../DocSettingsDialog/DocSettingsDialog';

export default function DocsTable(props) {
    const {
        rows,
        page,
        rowsPerPage,
        totalCnt = 0,
        handleChangePage,
        handleChangeRowsPerPage,
        handleUpdateData,
        uploading,
        onUploading,
        onAlert,
    } = props;

    const theme = useTheme();
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [selectedRow, setSelectedRow] = React.useState(null);

    const handleDialogOpen = (row) => {
        setSelectedRow(row);
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setSelectedRow(null);
        handleUpdateData();
    };

    const viewPdf = (url) => {
        window.open(url, '_blank');
    };

    return (
        <>
            <TableContainer component={Paper}>
                <Table size={'medium'}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">뷰어 아이디</TableCell>
                            <TableCell align="center">뷰어 키</TableCell>
                            <TableCell align="center">뷰어 열람</TableCell>
                            <TableCell align="center">뷰어 상태</TableCell>
                            <TableCell align="center">열람 수</TableCell>
                            <TableCell align="center">다운로드 수</TableCell>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            rows.map((row) => {
                                let disabled = false;
                                let state = row['view_state'];
                                let stateLabel = row['view_state'];
                                if (state === "1") {
                                    stateLabel = "정상";
                                } else if (state === "0") {
                                    disabled = true;
                                    stateLabel = "업로드 진행중";
                                } else if (state === "-1") {
                                    stateLabel = "점검중";
                                } else if (state === "-2") {
                                    stateLabel = "기간 만료";
                                } else if (state === "-3") {
                                    disabled = true;
                                    stateLabel = "업로드 에러";
                                }

                                return (
                                    <TableRow key={row['m_id']}
                                              style={{color: state === "1" ? "inherit" : theme.palette.secondary.main}}>
                                        <TableCell component="th" scope="row" align="center">
                                            {row['v_id']}
                                        </TableCell>
                                        <TableCell component="th" scope="row" align="center">
                                            {row['m_id']}
                                        </TableCell>
                                        <TableCell align="center">
                                            <IconButton disabled={disabled} onClick={() => {
                                                viewPdf(row['view_url'])
                                            }}>
                                                <Visibility fontSize="small"/>
                                            </IconButton>
                                        </TableCell>
                                        <TableCell align="center">{stateLabel}</TableCell>
                                        <TableCell align="center">{row['file_hit_count']}</TableCell>
                                        <TableCell align="center">{row['file_down_count']}</TableCell>
                                        <TableCell>
                                            <IconButton disabled={disabled} onClick={() => {
                                                handleDialogOpen(row)
                                            }}>
                                                <SettingsIcon fontSize="small"/>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 50, 100]}
                component="div"
                count={totalCnt}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />

            <DocSettingsDialog open={dialogOpen}
                               uploading={uploading}
                               onUploading={onUploading}
                               onAlert={onAlert}
                               onClose={handleDialogClose}
                               viewerId={selectedRow ? selectedRow['v_id'] : ""}
                               viewRegDate={selectedRow && selectedRow['file_regdate'] ? selectedRow['file_regdate'] : ""}
                               viewLastDate={selectedRow && selectedRow['file_last_date'] ? selectedRow['file_last_date'] : ""}
                               viewState={selectedRow && selectedRow['view_state'] ? selectedRow['view_state'] : ""}
            />
        </>
    );
};
