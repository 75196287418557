import React, {useState} from 'react';

/** libs **/
import {connect} from "react-redux";
import Cookies from "js-cookie";

/** material **/
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Card from "@material-ui/core/Card";
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";

/** define */
import Axios from "_define/Axios";

/** action **/
import {setUserInfo} from "actions/auth";

const useStyles = makeStyles((theme) => ({
    container: {
        border: 0,
        height: "auto",
        margin: 0,
        display: "inherit",
        padding: 0,
        position: "relative",
        minHeight: "100vh",
        alignItems: "center",
        '&:before': {
            content: '""',
            zIndex: 1,
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "block",
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
        },

        '&:after': {
            content: '""',
            zIndex: 1,
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "block",
        },
    },

    main: {
        color: "#ffffff",
        width: "100%",
        zIndex: 2,
        position: "relative",
        marginLeft: "auto",
        paddingTop: "20vh",
        marginRight: "auto",
        paddingLeft: "15px",
        paddingRight: "15px",
        paddingBottom: "200px",

        [theme.breakpoints.up('sm')]: {
            maxWidth: 540,
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: 960,
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: 1140,
        }
    },

    card: {
        maxWidth: 600,
        padding: theme.spacing(3),
        boxShadow: "none"
    },

    textInput: {
        marginBottom: theme.spacing(2)
    },

    divider: {
        marginBottom: theme.spacing(4),
    },

    snack: {
        background: theme.palette.primary.main
    },

    loading: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 9999,
        position: "fixed",
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.15)'
    },

    snackBar: {
        background: theme.palette.secondary.main
    }
}));

const Login = ({}) => {
    const classes = useStyles();

    const [id, setId] = useState("");
    const [password, setPassword] = useState("");

    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState("");

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setError(false);
    };

    const handleChangeId = (event) => {
        setId(event.target.value);
    };
    const handleChangePassword = (event) => {
        setPassword(event.target.value);
    };

    const onSign = async () => {
        if (uploading) {
            return;
        }

        if (!id || id.trim().length === 0) {
            setErrorText("아이디를 입력하세요");
            setError(true);
            return;
        }

        if (!password || password.trim().length === 0) {
            setErrorText("비밀번호를 입력하세요");
            setError(true);
            return;
        }

        setErrorText("");
        setError(false);
        setUploading(true);

        return Axios.post(`/api/auth/login`, {
            id: id,
            password: password
        }).then(({data}) => {
            Cookies.set('user', {
                id: data['userId'],
                name: data['userName'],
                type: data['userType'],
                token: data['token']
            }, {expires: 1, path: '/'});
            window.location.href = "/";
        }).catch(() => {
            setErrorText("아이디 또는 패스워드를 확인하세요");
            setError(true);
            setUploading(false);
        });
    };

    return (
        <>
            <div className={classes.container} style={{
                backgroundImage: `url(/resources/images/bg.jpg)`,
                backgroundSize: "cover",
                backgroundPosition: "center top"
            }}>
                <div className={classes.main}>
                    <Grid container justify={"center"}>
                        <Grid item xs={12} sm={12} md={4}>
                            <Card className={classes.card}>
                                <CardHeader title="로그인" titleTypographyProps={{
                                    variant: "h5",
                                    component: "h1",
                                }}>
                                </CardHeader>
                                <CardContent>
                                    <TextField
                                        className={classes.textInput}
                                        label="아이디"
                                        fullWidth
                                        inputProps={{type: "text"}}
                                        value={id}
                                        onChange={handleChangeId}
                                        helperText=" "
                                    />

                                    <TextField
                                        className={classes.textInput}
                                        label="비밀번호"
                                        fullWidth
                                        inputProps={{type: "password"}}
                                        value={password}
                                        onChange={handleChangePassword}
                                        helperText=" "
                                    />
                                </CardContent>
                                <CardActions>
                                    <Button size="large"
                                            color="primary"
                                            variant="outlined"
                                            fullWidth onClick={onSign}>
                                        로그인
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            </div>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={error}
                autoHideDuration={1000}
                onClose={handleCloseSnackBar}>
                <SnackbarContent
                    classes={{
                        root: classes.snackBar
                    }}
                    message={errorText}
                />
            </Snackbar>
        </>
    );
}

const mapStateToProps = () => ({});
export default connect(mapStateToProps)(Login);
