import {SET_USER, SET_AUTH, SET_UPLOADING, SET_SNACKBAR} from 'actions/auth';

const initialState = {
    id: "",
    name: "",
    type: -1,


    isAuth: false,
    uploading: false,
    isOpenSnackBar: false,
    snackBarMessage: ""
};

export default function view(state = initialState, action) {
    switch (action.type) {
        case SET_USER:
            const {id, name, type} = action.payload;
            return {
                ...state,
                id: id,
                name: name,
                type: type
            };


        case SET_AUTH:
            return {
                ...state,
                isAuth: action.payload
            };
        case SET_UPLOADING:
            return {
                ...state,
                uploading: action.payload
            };
        case SET_SNACKBAR:
            return {
                ...state,
                isOpenSnackBar: action.payload.status,
                snackBarMessage: action.payload.message,
            };
        default:
            return state;
    }
}
