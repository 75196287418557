import React from 'react';

/** libs **/
import clsx from 'clsx';
import {Link} from 'react-router-dom';

/** material **/
import {makeStyles} from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Cookies from "js-cookie";

const useStyles = makeStyles((theme) => ({
    logo: {
        ...theme.typography.h4,
    },

    item: {
        ...theme.typography.h6,
        padding: theme.spacing(1, 3),
        color: theme.palette.text.primary
    },

    itemCategory: {
        background: theme.palette.background.default,
        boxShadow: `0 -1px 0 ${theme.palette.grey[300]} inset`,
        padding: theme.spacing(2, 3),
    },

    itemIcon: {
        minWidth: 'auto',
        marginRight: theme.spacing(2),
    },

    categoryHeader: {
        ...theme.typography.h6,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },

    categoryHeaderText: {
        color: theme.palette.common.white,
    },

    itemActiveItem: {
        color: theme.palette.primary.main,
    },

    link: {
        textDecoration: "none",
        outline: "none",
        color: "inherit",
    },

    divider: {
        marginTop: theme.spacing(2),
    },
}));

export default function Navigator({settings, selectId, ...other}) {
    const classes = useStyles();

    let data = Cookies.getJSON('user');
    let categories = [
        {
            id: 'admin',
            text: '관리',
            children: [
                /*  {id: 'info', text: '시스템 정보', active: true},*/
                {id: 'settings', text: '시스템 관리'},
                {id: 'users', text: '사용자 관리'},
            ],
        },
        {
            id: 'doc',
            text: '문서',
            children: [
                {id: 'upload', text: '문서 등록'},
                {id: 'docs', text: '문서 관리'},
            ],
        }
    ];
    if (data && data.type === "1") {
        categories = [
            {
                id: 'admin',
                text: '관리',
                children: [
                    {id: 'users', text: '비밀번호 변경'},
                ],
            },
            {
                id: 'doc',
                text: '문서',
                children: [
                    {id: 'upload', text: '문서 등록'},
                    {id: 'docs', text: '문서 관리'},
                ],
            }
        ];
    }

    return (
        <Drawer variant="permanent" {...other}>
            <List disablePadding>
                <ListItem className={clsx(classes.logo, classes.item, classes.itemCategory)}>
                    <div>
                        <Typography color="inherit" variant="h5" component="h1">Mocha&JS</Typography>
                        <Typography color="inherit" variant="body1" component="p">v1.6</Typography>
                    </div>
                </ListItem>

                {categories.map(({id, text, children}) => (
                    <React.Fragment key={id}>
                        <ListItem className={classes.categoryHeader}>
                            <ListItemText>
                                {text}
                            </ListItemText>
                        </ListItem>
                        {children.map(({id: childId, text, icon}) => (
                            <Link key={childId} className={classes.link} to={`/admin/${childId}`}>
                                <ListItem
                                    button
                                    className={clsx(classes.item, (selectId === childId) && classes.itemActiveItem)}>

                                    <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                                    <ListItemText>
                                        {text}
                                    </ListItemText>
                                </ListItem>
                            </Link>
                        ))}

                        <Divider className={classes.divider}/>
                    </React.Fragment>
                ))}
            </List>
        </Drawer>
    );
}