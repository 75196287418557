import React, {useEffect, useState} from 'react';

/** libs **/
import Cookies from "js-cookie";
import {connect} from "react-redux";

/** material **/
import {makeStyles} from '@material-ui/core/styles';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";

/** components **/
import {UserTable} from "components/User";


/** define **/
import Axios from "_define/Axios";

const useStyles = makeStyles((theme) => ({
    content: {
        padding: theme.spacing(2)
    },

    card: {
        maxWidth: 345,
        margin: `${theme.spacing(3)}px auto`,
        boxShadow: "none"
    },

    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(2),
    },

    formControl: {
        margin: theme.spacing(1, 0),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },

    action: {
        display: "flex",
        justifyContent: "flex-end",
        margin: theme.spacing(2),
    },

    button: {
        padding: theme.spacing(1),
    }
}));

const Users = (props) => {
    const {
        uploading,
        onUploading,
        onAlert,
    } = props;

    const classes = useStyles();
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordCheck, setNewPasswordCheck] = useState("");

    const handleChangePassword = (event) => {
        setCurrentPassword(event.target.value);
    };

    const handleChangeNewPassword = (event) => {
        setNewPassword(event.target.value);
    };

    const handleChangeNewPasswordCheck = (event) => {
        setNewPasswordCheck(event.target.value);
    };

    const onUserUpdate = () => {
        if (uploading) {
            return;
        }

        if (currentPassword === "") {
            onAlert("현재 비밀번호를 입력하세요");
            return;
        }

        if (newPassword === "" || newPasswordCheck === "" || newPassword !== newPasswordCheck) {
            onAlert("새로운 비밀번호를 입력하세요");
            return;
        }

        onUploading(true);
        Axios.post(`/api/auth/userUpdate`, {
            password: currentPassword,
            newPassword: newPassword,
        }).then(() => {
            onAlert("변경 되었습니다.");
            onUploading(false);
        }).catch(({code}) => {
            if(code === 604){
                onAlert("비밀번호가 일치하지 않습니다");
            } else {
                onAlert("에러가 발생 했습니다");
            }

            onUploading(false);
        });
    };

    /* admin */
    const data = Cookies.getJSON('user');
    if (data && data.type === "0") {
        return (
            <div className={classes.content}>
                <UserTable {...props}/>
            </div>
        );
    }

    const isCheckPassword = (newPassword !== "" && newPasswordCheck !== "" && newPassword !== newPasswordCheck);
    return (
        <div className={classes.content}>
            <Card className={classes.card}>
                <CardContent className={classes.content}>
                    <form className={classes.form} noValidate>
                        <FormControl className={classes.formControl} fullWidth>
                            <InputLabel>현재 비밀번호</InputLabel>
                            <Input autoFocus
                                   value={currentPassword}
                                   onChange={handleChangePassword}/>
                        </FormControl>

                        <FormControl className={classes.formControl} fullWidth>
                            <InputLabel>새로운 비밀번호</InputLabel>
                            <Input autoFocus
                                   value={newPassword}
                                   onChange={handleChangeNewPassword}/>
                        </FormControl>

                        <FormControl fullWidth
                                     error={isCheckPassword}
                                     inputProps={{type: "password"}}
                                     className={classes.formControl}>
                            <InputLabel>새로운 비밀번호 확인</InputLabel>
                            <Input value={newPasswordCheck}
                                   inputProps={{type: "password"}}
                                   onChange={handleChangeNewPasswordCheck}/>
                            <FormHelperText style={{display: !isCheckPassword ? "none" : "block"}}>
                                비밀번호가 일치하지 않습니다.
                            </FormHelperText>
                        </FormControl>
                    </form>
                </CardContent>

                <CardActions className={classes.action}>
                    <Button className={classes.button}
                            color="primary"
                            variant="outlined"
                            onClick={onUserUpdate}>
                        수정
                    </Button>
                </CardActions>
            </Card>
        </div>
    );
}

const mapStateToProps = ({auth}) => ({user: auth});
export default connect(mapStateToProps)(Users);

