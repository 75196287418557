import React, {useEffect, useState} from "react";

/** material **/
import {makeStyles} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

/** components **/
import UserAddDialog from '../UserAddDialog/UserAddDialog';

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    button: {
        margin: theme.spacing(1),
    },
}));

const UserTableToolbar = ({onOpenDialog}) => {
    const classes = useStyles();

    return (
        <>
            <Toolbar>
                <Typography className={classes.title} variant="h6" noWrap>
                    사용자 관리
                </Typography>
                <div className={classes.grow}/>
                <Button
                    variant="outlined"
                    color="primary"
                    className={classes.button}
                    onClick={onOpenDialog}>
                    사용자 추가
                </Button>
            </Toolbar>
        </>
    );
};

export default UserTableToolbar;