import React from 'react';

/** material **/
import {lighten, makeStyles} from '@material-ui/core/styles';
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";

const useStyles = makeStyles((theme) => ({
    textInput: {
        marginBottom: theme.spacing(1)
    },

    upload: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: 40,
        border: `1px dashed ${theme.palette.text.secondary}`,
        borderRadius: 3,
        padding: theme.spacing(0, 3),
        transition: '0.2s',
        cursor: "pointer",
        "&:hover": {
            background: lighten(theme.palette.grey[200], 0.5)
        }
    },

    button: {
        ...theme.typography.button,
        display: "inline-block",
        border: "1px solid #c4c4c4",
        borderRadius: 3,
        padding: theme.spacing(0.5, 1),
        marginRight: theme.spacing(2),
        textTransform: "uppercase",
        cursor: "pointer",
    },

    hint: {
        ...theme.typography.body2,
        flex: 1,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },

    input: {
        position: "absolute",
        left: 0,
        top: 0,
        height: "100%",
        width: "100%",
        opacity: 0,
        cursor: "pointer",
        visibility: "hidden",
    },
}));
export default function UploadButton({inputRef, buttonTitle, previewName, onClick, onInputChange, error, errorText}) {
    const classes = useStyles();

    return (
        <FormControl variant="outlined"
                     className={classes.textInput}
                     error={error}
                     fullWidth>
            <div className={classes.upload} onClick={onClick}>
                <span className={classes.button}>{buttonTitle}</span>
                <span className={classes.hint}>{previewName}</span>
                <input ref={inputRef}
                       className={classes.input}
                       type="file"
                       name="files"
                       onChange={onInputChange}/>
            </div>
            <FormHelperText>{errorText}</FormHelperText>
        </FormControl>
    );
}