import Axios from '../_define/Axios';
import Cookies from 'js-cookie';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SET_AUTH = 'SET_AUTH';
export const SET_UPLOADING = 'SET_UPLOADING';
export const SET_SNACKBAR = 'SET_SNACKBAR';



export const SET_USER = 'SET_USER';

export const setUserInfo = (payload) => (dispatch) => {
    dispatch({type: SET_USER, payload: payload});

    return Promise.resolve();
}





export const setUploading = (payload) => (dispatch) => {
    dispatch({type: SET_UPLOADING, payload: payload});
}

export const setSnackBar = (payload) => (dispatch) => {
    dispatch({type: SET_SNACKBAR, payload: payload});
}

export const login = ({id, password}) => (dispatch) => {
    dispatch({type: SET_UPLOADING, payload: true});

    return Axios.post(`/api/auth/login`, {
        id: id,
        password: password
    }).then(({data}) => {
        Cookies.set('user', data.token, {path: '/'});
        dispatch({type: SET_AUTH, payload: true});
    }).catch((error) => {
        dispatch({
            type: SET_SNACKBAR, payload: {
                status: true,
                message: error.message
            }
        });
    }).then(function () {
        // always executed
        dispatch({type: SET_UPLOADING, payload: false});
    });
}

export const check = () => (dispatch) => {
    const token = Cookies.get("user");
    if(token){
        /** token check **/
        dispatch({type: SET_AUTH, payload: true});
    } else {
        dispatch({type: SET_AUTH, payload: false});
    }
}

export const logout = () => (dispatch) => {
    Cookies.remove('user', {path: '/'});
    dispatch({type: SET_AUTH, payload: false});
}