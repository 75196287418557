import React, {useEffect, useState} from "react";

/** material **/
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(2),
    },

    formControl: {
        margin: theme.spacing(1, 0),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },

    action: {
        padding: theme.spacing(1),
        margin: theme.spacing(2),
    },
}));

export default function UserAddDialog({open, onClose}) {
    const classes = useStyles();
    return (
        <Dialog
            fullWidth={true}
            maxWidth={"xs"}
            open={open}
            onClose={onClose}>
            <DialogTitle>사용자 추가</DialogTitle>
            <DialogContent>
                <form className={classes.form} noValidate>
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel>아이디</InputLabel>
                        <Input autoFocus/>
                    </FormControl>
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel>이름</InputLabel>
                        <Input/>
                    </FormControl>
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel>비밀번호</InputLabel>
                        <Input/>
                    </FormControl>
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel>비밀번호 확인</InputLabel>
                        <Input/>
                    </FormControl>
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel>타입</InputLabel>
                        <Select
                            onChange={() => {
                            }}>
                            <MenuItem value="user">사용자</MenuItem>
                            <MenuItem value="admin">관리자</MenuItem>
                        </Select>
                    </FormControl>
                </form>
            </DialogContent>
            <DialogActions>
                <Button className={classes.action}
                        color="primary"
                        variant="outlined"
                        fullWidth
                        onClick={() => {
                        }}>
                    추가
                </Button>
            </DialogActions>
        </Dialog>
    );
};
