import React, {useEffect, useState} from 'react';

/** material **/
import {makeStyles} from '@material-ui/core/styles';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";


const useStyles = makeStyles((theme) => ({
    card: {
        maxWidth: 345,
        margin: `${theme.spacing(3)}px auto`,
        boxShadow: "none"
    },

    content: {
        padding: theme.spacing(2, 0.5)
    },

    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(2),
    },

    formControl: {
        margin: theme.spacing(1, 0),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },

    action: {
        display: "flex",
        justifyContent: "flex-end",
        margin: theme.spacing(2),
    },

    button: {
        padding: theme.spacing(1),
    }
}));

export default function ServerSettings(props) {
    const classes = useStyles();
    const {
        hostUrl,
        viewTerm,
        hashKeyLength,
        viewState,
        uploadPath,
        onUpdate,
        onChange
    } = props;

    const handleChangeHostUrl = (event) => {
        onChange(event, "hostUrl");
    };

    const handleUploadPath = (event) => {
        onChange(event, "uploadPath");
    };

    const handleChangeViewTerm = (event) => {
        onChange(event, "viewTerm");
    };

    const handleChangeHashKeyLength = (event) => {
        onChange(event, "hashKeyLength");
    };

    const handleChangeViewState = (event) => {
        onChange(event, "viewState");
    };

    const handleUpdate = () => {
        onUpdate();
    };

    return (
        <Card className={classes.card}>
            <CardContent className={classes.content}>
                <form className={classes.form} noValidate>
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel>업로드 폴더 설정</InputLabel>
                        <Input value={uploadPath} onChange={handleUploadPath}/>
                        <FormHelperText>뷰어가 설치 된 서버 환경을 기준으로 패스를 설정 합니다</FormHelperText>
                    </FormControl>

                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel>서버 상태 설정</InputLabel>
                        <Select
                            value={viewState}
                            onChange={handleChangeViewState}>
                            <MenuItem value="1">정상</MenuItem>
                            <MenuItem value="-1">점검중</MenuItem>
                            <MenuItem value="0">중지</MenuItem>
                        </Select>
                        <FormHelperText>문서 서버의 상태를 설정 합니다.</FormHelperText>
                    </FormControl>

                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel>문서 주소 설정</InputLabel>
                        <Input value={hostUrl} onChange={handleChangeHostUrl}/>
                        <FormHelperText>문서 HOST URL을 설정 합니다</FormHelperText>
                    </FormControl>


                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel>문서 개시 기간</InputLabel>
                        <Select
                            value={viewTerm}
                            onChange={handleChangeViewTerm}>
                            <MenuItem value="-1">영구</MenuItem>
                            <MenuItem value="30">30일</MenuItem>
                            <MenuItem value="60">60일</MenuItem>
                            <MenuItem value="90">90일</MenuItem>
                            <MenuItem value="120">120일</MenuItem>
                        </Select>
                        <FormHelperText>등록 일로 부터 개시할 뷰어의 기간을 설정 합니다.</FormHelperText>
                    </FormControl>

                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel>문서 키 설정</InputLabel>
                        <Select
                            value={hashKeyLength}
                            onChange={handleChangeHashKeyLength}>
                            <MenuItem value="12">최소 12자리</MenuItem>
                            <MenuItem value="24">최소 24자리</MenuItem>
                            <MenuItem value="36">최소 36자리</MenuItem>
                        </Select>
                        <FormHelperText>문서 뷰어의 키의 길이를 설정 합니다.</FormHelperText>
                    </FormControl>
                </form>
            </CardContent>

            <CardActions className={classes.action}>
                <Button className={classes.button}
                        color="primary"
                        variant="outlined"
                        onClick={handleUpdate}>
                    적용
                </Button>
            </CardActions>
        </Card>
    );
}