import React, {useState, useEffect} from 'react';

/** libs  **/
import moment from 'moment';

/** material **/
import {makeStyles} from "@material-ui/core/styles";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from "@material-ui/core/Divider";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";

/** define **/
import Axios from "../../../_define/Axios";

const useStyles = makeStyles((theme) => ({
    card: {
        boxShadow: "none",
        background: "transparent"
    },

    textInput: {},

    details: {
        display: 'flex',
        flexDirection: 'column',
    },

    content: {
        flex: '1 0 auto',
    },

    upload: {
        display: "flex",
        position: "relative",
        alignItems: "center"
    },

    uploadInput: {
        marginRight: theme.spacing(2)
    },

    action: {
        padding: 0,
        justifyContent: "flex-end"
    },

    input: {
        position: "absolute",
        left: 0,
        top: 0,
        height: "100%",
        width: "100%",
        opacity: 0,
        cursor: "pointer",
        visibility: "hidden",
    },

    divider: {
        margin: theme.spacing(2, 0)
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ViewerSettingsDialog(props) {
    const {
        open,
        onClose,
        viewerId,
        viewRegDate,
        viewLastDate,
        viewState,
        uploading,
        onUploading,
        onAlert
    } = props;

    const classes = useStyles();
    const [regDate, setRegDate] = useState(viewRegDate);
    const [lastDate, setLastDate] = useState(viewLastDate !== "" ? moment.unix(viewLastDate).format("YYYY-MM-DD") : viewLastDate);
    const [status, setStatus] = useState(viewState);

    const [deleteGroupId, setDeleteGroupId] = useState("");
    const [isEnableDeleteButton, setIsEnableDeleteButton] = useState(false);

    const [error, setError] = useState({lastDate: false});
    const [errorText, setErrorText] = useState({lastDate: " "});

    const handleChangeLastDate = (event) => {
        let value = event.target.value;
        if (moment(value, 'YYYY-MM-DD', true).isValid()) {
            setError({...error, lastDate: false});
            setErrorText({...error, lastDate: " "});
        } else {
            setError({...error, lastDate: true});
            setErrorText({...error, lastDate: "유효 하지 않은 데이터 포맷 입니다. (YYYY-MM-DD)"});
        }
        setLastDate(value);
    };

    const handleChangeStatus = (event) => {
        setStatus(event.target.value);
    };

    const handleChangeDeleteGroupId = (event) => {
        const value = event.target.value;
        if (value === viewerId) {
            setIsEnableDeleteButton(true);
        } else {
            setIsEnableDeleteButton(false);
        }
        setDeleteGroupId(value);
    };

    const initError = () => {
        setError({
            frontFile: false,
            backFile: false,
        });

        setErrorText({
            frontFile: " ",
            backFile: " ",
        });
    };

    /** api **/
    const deleteView = (event) => {
        event.stopPropagation();
        event.preventDefault();

        if (uploading) {
            return;
        }

        if (!viewerId) {
            return;
        }

        initError();
        onUploading(true);
        Axios.post(`/api/doc/delete`, {
            docId: viewerId,
        }).then(() => {
            onAlert("삭제 되었습니다.");
            onUploading(false);
            onClose();
        }).catch(() => {
            onAlert("에러가 발생 했습니다.");
            onUploading(false);
        });
    };

    const updateView = (event) => {
        event.stopPropagation();
        event.preventDefault();

        if (uploading) {
            return;
        }

        if (!viewerId) {
            return;
        }

        let _lastDate = null;
        let _state = null;
        if (moment(lastDate, 'YYYY-MM-DD', true).isValid()) {
            if (moment.unix(viewLastDate).format("YYYY-MM-DD") !== lastDate) {
                _lastDate = moment(lastDate).unix();
            }
        }

        if (viewState !== status) {
            _state = status;
        }

        if (!_lastDate && !_state) {
            return;
        }

        initError();
        onUploading(true);

        Axios.post(`/api/doc/update`, {
            docId: viewerId,
            expiredTerm: _lastDate,
            viewState: _state,
        }).then(() => {
            onAlert("변경 되었습니다.");
            onUploading(false);
        }).catch(() => {
            onAlert("에러가 발생 했습니다");
            onUploading(false);
        });
    };

    useEffect(() => {
        setDeleteGroupId("");
        setIsEnableDeleteButton(false);
    }, [viewerId]);

    useEffect(() => {
        setRegDate(viewRegDate);
    }, [viewRegDate]);

    useEffect(() => {
        setLastDate(viewLastDate !== "" ? moment.unix(viewLastDate).format("YYYY-MM-DD") : viewLastDate);
    }, [viewLastDate]);

    useEffect(() => {
        setStatus(viewState);
    }, [viewState]);


    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}>
            <DialogContent>
                <Card className={classes.card}>
                    <div className={classes.details}>
                        <CardHeader title="뷰어 문서 설정"
                                    titleTypographyProps={{variant: "h6"}}/>

                        <FormControl variant="outlined" fullWidth className={classes.textInput}>
                            <InputLabel htmlFor="viewer-doc-status">파일 확장자</InputLabel>
                            <Select
                                native
                                margin="dense"
                                value={status}
                                label="문서 뷰어 상태"
                                inputProps={{
                                    id: 'viewer-doc-status',
                                }}
                                /*disabled={status === "0"}*/
                                onChange={handleChangeStatus}>
                                <option value="1">정상</option>
                                <option value="-1">점검중</option>
                                <option value="-2">기간 만료</option>
                            </Select>
                            <FormHelperText>{" "}</FormHelperText>
                        </FormControl>

                        <TextField
                            className={classes.textInput}
                            margin="dense"
                            variant="outlined"
                            label="등록일"
                            fullWidth
                            value={moment.unix(regDate).format("YYYY-MM-DD")}
                            helperText={" "}
                            disabled
                        />

                        <TextField
                            className={classes.textInput}
                            margin="dense"
                            variant="outlined"
                            label="만료일"
                            fullWidth
                            value={lastDate}
                            error={error.lastDate}
                            helperText={errorText.lastDate}
                            onChange={handleChangeLastDate}
                        />


                        <CardActions className={classes.action} disabled>
                            <Button variant="outlined" color="primary" fullWidth onClick={updateView} >
                                약관 수정
                            </Button>
                        </CardActions>
                    </div>
                </Card>

                <Divider className={classes.divider}/>

                <Card className={classes.card}>
                    <div className={classes.details}>
                        <CardHeader title="뷰어 문서 삭제"
                                    titleTypographyProps={{color: "secondary", variant: "h6"}}/>

                        <CardContent className={classes.content}>
                            <Typography variant="body2" color={"textSecondary"}>뷰어 문서를 삭제하면 되돌릴 수 없습니다.</Typography>

                            <TextField
                                margin="dense"
                                color="secondary"
                                type="text"
                                label={viewerId}
                                value={deleteGroupId}
                                onChange={handleChangeDeleteGroupId}
                                fullWidth
                            />
                        </CardContent>

                        <CardActions className={classes.action}>
                            <Button className={classes.textInput}
                                    variant="outlined"
                                    color="secondary"
                                    disabled={!isEnableDeleteButton}
                                    onClick={deleteView}>
                                뷰어 문서 삭제
                            </Button>
                        </CardActions>
                    </div>
                </Card>
            </DialogContent>
        </Dialog>
    );
}