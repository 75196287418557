import '@babel/polyfill';

import React from 'react';
import { render } from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import  { composeWithDevTools } from "redux-devtools-extension";
import thunk from 'redux-thunk';

import { BrowserRouter } from 'react-router-dom';
import {rootReducers} from "./reducers";

import App from './App';
import * as serviceWorker from './serviceWorker';

import './index.scss';
//import ReactGA from 'react-ga';

/** sage 연결 **/
const middleware = [
    thunk,
];

let store;
if(process.env.NODE_ENV === 'development') {
    //const { logger } = require("redux-logger");
    //middleware.push(logger);
    store = createStore(rootReducers, composeWithDevTools(applyMiddleware(...middleware)));
} else {
    store = createStore(rootReducers, applyMiddleware(...middleware));
}

render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>,

    document.getElementById('dp-app')
);

serviceWorker.unregister();