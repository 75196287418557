import React, {useState, useEffect} from 'react';

/** libs **/
import {connect} from "react-redux";
import {Route, Switch, Redirect} from "react-router-dom";

/** material **/
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';

/** actions **/
import {check} from "actions/auth";

/** component **/
import {MainView} from "./views";
import {Login, Info, Settings, Users, Upload, Docs} from "./containers";
import Cookies from "js-cookie";

const App = ({dispatch, isAuth}) => {
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [isInit, setIsInit] = useState(true);

    let theme = React.useMemo(() => createMuiTheme({
        palette: {
            type: isDarkMode ? 'dark' : 'light',
            primary: {
                main: "#228be6",
            }
        },

        typography: {
            h1: {fontSize: "2rem", lineHeight: 1.4, fontWeight: 600},
            h2: {fontSize: "1.7rem", lineHeight: 1.4, fontWeight: 600},
            h3: {fontSize: "1.5rem", lineHeight: 1.4, fontWeight: 600},
            h4: {fontSize: "1.25rem", lineHeight: 1.4, fontWeight: 600},
            h5: {fontSize: "1rem", lineHeight: 1.4, fontWeight: 600},
            h6: {fontSize: "0.85714rem", lineHeight: 1.4, fontWeight: 600},

            subtitle1: {fontSize: "1rem", lineHeight: 1.4, fontWeight: 600},
            subtitle2: {fontSize: "0.75rem", lineHeight: 1.4, fontWeight: 600},
            body1: {fontSize: "0.85714rem", lineHeight: 1.4},
            body2: {fontSize: "0.75rem", lineHeight: 1.4},
            button: {fontSize: "0.75rem", lineHeight: 1.4},
        }
    }), [isDarkMode]);
    theme = {
        ...theme,
        overrides: {
            MuiPaper: {
                root: {
                    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)'
                },
                elevation1: {
                    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)'
                },
                elevation2: {
                    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)'
                },
            },

            MuiDrawer: {
                paper: {
                    backgroundColor: theme.palette.background.default,
                },
            },
            MuiButton: {
                label: {
                    textTransform: 'none',
                },
                contained: {
                    boxShadow: 'none',
                    '&:active': {
                        boxShadow: 'none',
                    },
                },
            },
            MuiTabs: {
                root: {
                    marginLeft: theme.spacing(1),
                },
                indicator: {
                    height: 3,
                    borderTopLeftRadius: 3,
                    borderTopRightRadius: 3,
                    backgroundColor: theme.palette.common.white,
                },
            },
            MuiTab: {
                root: {
                    textTransform: 'none',
                    margin: '0 16px',
                    minWidth: 0,
                    padding: 0,
                    [theme.breakpoints.up('md')]: {
                        padding: 0,
                        minWidth: 0,
                    },
                },
            },
            MuiIconButton: {
                root: {
                    padding: theme.spacing(1),
                },
            },
            MuiTooltip: {
                tooltip: {
                    borderRadius: 4,
                },
            },
            MuiDivider: {
                root: {
                    backgroundColor: theme.palette.grey[300]
                },
            },
            MuiListItemText: {
                primary: {
                    fontWeight: theme.typography.fontWeightMedium,
                },
            },
            MuiListItemIcon: {
                root: {
                    color: 'inherit',
                    marginRight: 0,
                    '& svg': {
                        fontSize: 20,
                    },
                },
            },
            MuiAvatar: {
                root: {
                    width: 32,
                    height: 32,
                },
            },

            MuiTableCell: {
                root: {
                    padding: theme.spacing(1),
                },
                body: {
                    color: "inherit"
                }
            }
        },
    }

    useEffect(() => {
        dispatch(check());
    }, []);

    const data = Cookies.getJSON('user');
    let isToken = data && data.token;
    let pathname = window.location.pathname;
    let search = window.location.search;

    if (pathname === "/admin/login") {
        pathname = "/admin";
    }

    return (
        <ThemeProvider theme={theme}>
            {!isToken ? <Redirect to="/admin/login"/> : <Redirect to={`${pathname}${search}`}/>}

            <Switch>
                <Route exact path="/admin/login" component={Login}/>

                <Route exact path="/admin/settings"
                       render={routerProps => (
                           <MainView viewId="settings" title={"시스템 관리"}><Settings {...routerProps}/></MainView>)}/>

                <Route exact path="/admin/users"
                       render={routerProps => (
                           <MainView viewId="users" title={"사용자 관리"}><Users {...routerProps}/></MainView>)}/>


                <Route exact path="/admin/docs"
                       render={routerProps => (
                           <MainView viewId="docs" title={"문서 관리"}><Docs {...routerProps}/></MainView>)}/>

                <Route path="/"
                       render={routerProps => (
                           <MainView viewId="upload" title={"문서 등록"}><Upload {...routerProps}/></MainView>)}/>
{/*
                <Route path="/"
                       render={routerProps => (
                           <MainView viewId="info" title={"시스템 정보"}><Info {...routerProps}/></MainView>)}/>*/}
            </Switch>

        </ThemeProvider>
    );
}


const mapStateToProps = ({auth}) => ({...auth});
export default connect(mapStateToProps)(App);

