import React, {useEffect, useState} from "react";

/** material **/
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Axios from "../../../_define/Axios";
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(2),
    },

    formControl: {
        margin: theme.spacing(1, 0),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },

    action: {
        padding: theme.spacing(1),
        margin: theme.spacing(2),
    },
}));

export default function UserAddDialog({open, uploading, onUploading, onAlert, onClose}) {
    const classes = useStyles();

    const [id, setId] = useState("");
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [passwordCheck, setPasswordCheck] = useState("");


    const handleChangeId = (event) => {
        setId(event.target.value);
    };

    const handleChangePassword = (event) => {
        setPassword(event.target.value);
    };

    const handleChangePasswordCheck = (event) => {
        setPasswordCheck(event.target.value);
    };

    const handleChangeName = (event) => {
        setName(event.target.value);
    };

    const onCreate = () => {
        if (uploading) {
            return;
        }

        if (id === "") {
            onAlert("아이디를 입력 하세요");
            return;
        }

        if (name === "") {
            onAlert("이름을 입력 하세요");
            return;
        }

        if (password === "" || passwordCheck === "" || password !== passwordCheck) {
            onAlert("비밀번호를 입력 하세요");
            return;
        }

        onUploading(true);
        Axios.post(`/api/auth/userCreate`, {
            id: id,
            name: name,
            password: password,
        }).then((data) => {
            setName("");
            setPassword("");
            setPasswordCheck("");

            onAlert("추가 되었습니다.");
            onUploading(false);
            onClose();
        }).catch((error) => {
            onAlert("에러가 발생 했습니다.");
            onUploading(false);
        });
    };

    const isCheckPassword = (password !== "" && passwordCheck !== "" && password !== passwordCheck);
    return (
        <Dialog
            fullWidth={true}
            maxWidth={"xs"}
            open={open}
            onClose={onClose}>
            <DialogTitle>사용자 추가</DialogTitle>
            <DialogContent>
                <form className={classes.form} noValidate>
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel>아이디</InputLabel>
                        <Input autoFocus
                               value={id}
                               onChange={handleChangeId}/>
                    </FormControl>
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel>이름</InputLabel>
                        <Input value={name}
                               onChange={handleChangeName}/>
                    </FormControl>
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel>비밀번호</InputLabel>
                        <Input value={password}
                               inputProps={{type: "password"}}
                               onChange={handleChangePassword}/>
                    </FormControl>
                    <FormControl fullWidth
                                 error={isCheckPassword}
                                 className={classes.formControl}>
                        <InputLabel>비밀번호 확인</InputLabel>
                        <Input value={passwordCheck}
                               inputProps={{type: "password"}}
                               onChange={handleChangePasswordCheck}/>
                        <FormHelperText style={{display: !isCheckPassword ? "none" : "block"}}>
                            비밀번호가 일치하지 않습니다.
                        </FormHelperText>
                    </FormControl>
                </form>
            </DialogContent>
            <DialogActions>
                <Button className={classes.action}
                        color="primary"
                        variant="outlined"
                        fullWidth
                        onClick={onCreate}>
                    추가
                </Button>
            </DialogActions>
        </Dialog>
    );
};
