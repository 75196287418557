import React, {useState} from 'react';

/** libs **/
import {connect} from "react-redux";
/** material **/
import {makeStyles} from '@material-ui/core/styles';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

/** components **/
import {SingleUpload, MultiUpload} from "components/Upload";

/** actions **/
import {setSnackBar, setUploading} from "actions/auth";

/** define **/
import Axios from '_define/Axios';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2)
    },
}));

const Upload = ({dispatch, history, uploading, onUploading, onAlert}) => {
    const classes = useStyles();
    const [tabValue, setTabValue] = React.useState(0);
    const [isCheckId, setIsCheckId] = useState(false);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleCheckId = (docId) => {
        if (uploading) {
            return;
        }

        Axios.get(`/api/doc/${docId}`).then(({data}) => {
            const result = data.data;

            let isCheck = false;
            let message = "이미 아이디가 존재합니다."
            if (!result) {
                isCheck = true;
                message = "아이디 사용이 가능합니다."
            }
            onAlert(message);
            onUploading(false);
            setIsCheckId(isCheck);

        }).catch(() => {
            onAlert("에러가 발생 했습니다.");
            onUploading(false);
        });
    };

    const handleSingleUpload = (docId, docExt, file) => {
        if (uploading) {
            return;
        }

        const formData = new FormData();
        formData.append("docId", docId.normalize());
        formData.append("docExt", docExt);
        formData.append("docFile", file);

        onUploading(true);
        Axios.post(`/api/doc`, formData).then(() => {
            onAlert("추가 되었습니다.");
            onUploading(false);
            //history.push(`/admin/viewer`);
        }).catch(() => {
            onAlert("에러가 발생 했습니다.");
            onUploading(false);
        });
    };

    return (
        <>
            <div className={classes.root}>
                <Tabs
                    value={tabValue}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleTabChange}>
                    <Tab label="단건 업로드"/>
                    {/*<Tab label="대량 업로드"/>*/}
                </Tabs>

                <div>
                    {tabValue === 0 ? <SingleUpload isCheckId={isCheckId}
                                                    uploading={uploading}
                                                    onCheckId={handleCheckId}
                                                    onUpload={handleSingleUpload}/> : null}
                </div>
                {/*<div>
                    {tabValue === 1 ? <MultiUpload isCheckId={isCheckId}
                                                    uploading={uploading}
                                                    onCheckId={handleCheckId}
                                                    onUpload={handleSingleUpload}/> : null}
                </div>*/}
            </div>
        </>
    );
}


const mapStateToProps = ({auth}) => ({...auth});
export default connect(mapStateToProps)(Upload);

