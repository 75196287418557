import React, {useEffect, useState} from "react";

/** material **/
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import FormHelperText from "@material-ui/core/FormHelperText";

/** define **/
import Axios from "_define/Axios";
import Config from "_define/Config";

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(2),
    },

    formControl: {
        margin: theme.spacing(1, 0),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },

    action: {
        padding: theme.spacing(1),
        margin: theme.spacing(2),
    },
}));

export default function UserUpdateDialog({open, id, name, type, uploading, onUploading, onAlert, onClose}) {
    const classes = useStyles();
    const [userId, setUserId] = useState(id);
    const [userName, setUserName] = useState(name);
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordCheck, setNewPasswordCheck] = useState("");
    const [isUpdatePassword, setIsUpdatePassword] = useState(false);

    useEffect(() => {
        setUserId(id);
        setUserName(name);
        setIsUpdatePassword(false);
    }, [id, name, type]);

    const handleChangeName = (event) => {
        setUserName(event.target.value);
    };

    const handleChangeNewPassword = (event) => {
        setNewPassword(event.target.value);
    };

    const handleChangeNewPasswordCheck = (event) => {
        setNewPasswordCheck(event.target.value);
    };

    const onUpdate = () => {
        if (uploading) {
            return;
        }

        if (name === "") {
            onAlert("이름을 입력 하세요");
            return;
        }

        if (isUpdatePassword && (newPassword === "" || newPasswordCheck === "" || newPassword !== newPasswordCheck)) {
            onAlert("새로운 비밀번호를 입력하세요");
            return;
        }

        onUploading(true);
        Axios.post(`/api/auth/userUpdate`, {
            id: id,
            name: userName,
            newPassword: newPassword,
            isUpdatePassword: isUpdatePassword
        }).then((data) => {
            onUploading(false);
            onAlert("변경 되었습니다.");
        }).catch(({code}) => {
            onUploading(false);
            onAlert("에러가 발생 했습니다.");
        });
    };

    const isCheckPassword = (newPassword !== "" && newPasswordCheck !== "" && newPassword !== newPasswordCheck);
    return (
        <Dialog
            fullWidth={true}
            maxWidth={"xs"}
            open={open}
            onClose={onClose}>
            <DialogTitle>사용자 수정</DialogTitle>
            <DialogContent>
                <form className={classes.form} noValidate>
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel>아이디</InputLabel>
                        <Input value={userId}
                               disabled/>
                    </FormControl>
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel>이름</InputLabel>
                        <Input value={userName}
                               onChange={handleChangeName}/>
                    </FormControl>

                    {isUpdatePassword ?
                        <>
                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel>새로운 비밀번호</InputLabel>
                                <Input value={newPassword}
                                       type="password"
                                       onChange={handleChangeNewPassword}/>
                            </FormControl>

                            <FormControl fullWidth
                                         error={isCheckPassword}
                                         type="password"
                                         className={classes.formControl}>
                                <InputLabel>비밀번호 확인</InputLabel>
                                <Input value={newPasswordCheck}
                                       type="password"
                                       onChange={handleChangeNewPasswordCheck}/>
                                <FormHelperText style={{display: !isCheckPassword ? "none" : "block"}}>
                                    비밀번호가 일치하지 않습니다.
                                </FormHelperText>
                            </FormControl>
                        </> : <Button color="inherit"
                                      variant="outlined"
                                      className={classes.formControl}
                                      fullWidth
                                      onClick={() => {
                                          setIsUpdatePassword(true)
                                      }}>
                            비밀번호 변경
                        </Button>}

                </form>
            </DialogContent>
            <DialogActions>
                <Button className={classes.action}
                        color="primary"
                        variant="outlined"
                        fullWidth
                        onClick={onUpdate}>
                    수정
                </Button>
            </DialogActions>
        </Dialog>
    );
};
