export default class Config {
    static get SERVER_API() {
        return '';
    }

    static get SERVER_ERROR_CODE() {
        return  {
            /** viewer **/
            NOT_FOUND_KEY: 500,
            NOT_FOUND_DATABASE: 501,
            STATUS_EXPIRED_DATE: 502,
            STATUS_SYSTEM_CHECK: 503,
            UPDATE_HIT_COUNT: 504,
            UPDATE_DOWN_COUNT: 505,
            UPDATE_LOG_HIT_COUNT: 506,
            UPDATE_LOG_DOWN_COUNT: 507,

            /** auth **/
            NOT_LOGGED: 600,
            LOGIN_ERROR: 601,

            /** parameter **/
            PARAM: 700,
            PARAM_FILE: 701,
            PARAM_NOT_MATCH_EXT: 702,
            WORKING_GROUP: 703,
            WORKING_DOC: 704,
            ALREADY_GROUP_ID: 705,
            ALREADY_DOC_ID: 706,
            NOT_FOUND_GROUP_ID: 707,
            NOT_FOUND_DOC_ID: 708,
            NOT_FOUND_VERSION_ID: 709,
            NOT_FOUND_FILE: 710,
            ERROR_WORK_GROUP: 711,

            /** database **/
            DATABASE_GROUP_SELECT: 800,
            DATABASE_GROUP_INSERT: 801,
            DATABASE_GROUP_UPDATE: 802,
            DATABASE_GROUP_DELETE: 803,
            DATABASE_DOC_SELECT: 804,
            DATABASE_DOC_INSERT: 805,
            DATABASE_DOC_UPDATE: 806,
            DATABASE_DOC_DELETE: 807,
            DATABASE_VIEWER_SELECT: 808,
            DATABASE_VIEWER_INSERT: 809,
            DATABASE_VIEWER_UPDATE: 810,
            DATABASE_VIEWER_DELETE: 811,
            DATABASE_VIEWER_TEMP_SELECT: 812,
            DATABASE_VIEWER_TEMP_INSERT: 813,
            DATABASE_VIEWER_TEMP_UPDATE: 814,
            DATABASE_VIEWER_TEMP_DELETE: 815,
            DATABASE_VERSION_SELECT: 816,
            DATABASE_VERSION_INSERT: 817,
            DATABASE_VERSION_UPDATE: 818,
            DATABASE_VERSION_DELETE: 819,
            DATABASE_SETTING_SELECT: 820,
            DATABASE_SETTING_INSERT: 821,
            DATABASE_SETTING_UPDATE: 822,
            DATABASE_SETTING_DELETE: 823,

            /** system **/
            SYSTEM_FILE_IO: 900,

            /** convert **/
            CONVERT_MODULE: 901,
        };
    }
}