import React, {useState, useRef} from 'react';

/** material **/
import {makeStyles} from '@material-ui/core/styles';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

/** components **/
import {UploadButton} from "components/Common";
import Input from "@material-ui/core/Input";

const useStyles = makeStyles((theme) => ({
    card: {
        maxWidth: 345,
        margin: `${theme.spacing(3)}px auto`,
        boxShadow: "none"
    },

    content: {
        padding: theme.spacing(2, 0.5)
    },

    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(2),
    },

    formControl: {
        margin: theme.spacing(1, 0),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },

    action: {
        display: "flex",
        justifyContent: "flex-end",
        margin: theme.spacing(2),
    },

    button: {
        padding: theme.spacing(1),
    }
}));

export default function SingleUpload({uploading, isCheckId, onCheckId, onUpload}) {
    const classes = useStyles();
    const fileInput = useRef(null);

    const [docId, setDocId] = useState("");
    const [docExt, setDocExt] = useState("pdf");
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState("");

    const [error, setError] = useState({docId: false, file: false});
    const [errorText, setErrorText] = useState({docId: " ", file: " "});

    const handleChangeDocId = (event) => {
        setDocId(event.target.value);
    };

    const handleChangeDocExt = (event) => {
        setDocExt(event.target.value);
    };

    const handleBrowser = () => {
        fileInput.current.click();
    };

    const initError = () => {
        setError({
            docId: false,
            file: false
        });

        setErrorText({
            docId: " ",
            file: " "
        });
    };

    const handleFileChange = (event) => {
        event.stopPropagation();
        event.preventDefault();

        let file = event.target.files[0];
        if (file) {
            /** validate check **/
            const supportFormats = ["pdf"]; //["pdf", "doc", "docx", "xls", "xlsx", "ppt", "pptx"];
            const file_extension = file.name.split(".").pop().toLowerCase();

            let isValid = false;
            for (let i = 0; i <= supportFormats.length; i++) {
                if (supportFormats[i] === file_extension) {
                    isValid = true;
                }
            }

            if (!isValid) {
                setError({...error, file: true});
                setErrorText({...errorText, file: "지원 하지 않는 문서 포맷 입니다."});
                return;
            }

            initError();
            setFileName(file && file.name ? file.name : "");
            setFile(file);
        }
    };

    /** api **/
    const handleCheckId = (event) => {
        event.stopPropagation();
        event.preventDefault();

        if (uploading) {
            return;
        }

        if (!docId || docId.trim().length === 0) {
            setError({...error, docId: true});
            setErrorText({...errorText, docId: "문서 아이디를 입력하세요."});
            return;
        }

        initError();
        onCheckId(docId);
    };

    const handleUpload = (event) => {
        event.stopPropagation();
        event.preventDefault();

        if (uploading) {
            return;
        }

        if (!docId || docId.trim().length === 0) {
            setError({...error, docId: true});
            setErrorText({...errorText, docId: "문서 아이디를 입력하세요."});
            return;
        }

        if (!file) {
            setError({...error, file: true});
            setErrorText({...errorText, file: "선택된 파일이 없습니다."});
            return;
        }

        initError();
        onUpload(docId, docExt, file);
    };

    return (
        <>
            <Card className={classes.card}>
                <CardContent className={classes.content}>
                    <FormControl className={classes.formControl}
                                 error={error.docId}
                                 fullWidth>
                        <InputLabel>문서 아이디</InputLabel>
                        <Input valu={docId}
                               onChange={handleChangeDocId}/>
                        <FormHelperText>{errorText.docId}</FormHelperText>
                    </FormControl>

                    {/*<FormControl className={classes.formControl} fullWidth>
                        <InputLabel>파일 확장자</InputLabel>
                        <Select
                            native
                            margin="dense"
                            value={docExt}
                            label="파일 확장자"
                            inputProps={{
                                id: 'upload-doc-ext',
                            }}
                            onChange={handleChangeDocExt}>
                            <option value="pdf">PDF</option>
                            <option value="doc">DOC</option>
                            <option value="docx">DOCX</option>
                            <option value="xls">XLS</option>
                            <option value="xlsx">XLSX</option>
                            <option value="ppt">PPT</option>
                            <option value="pptx">PPTX</option>
                        </Select>
                        <FormHelperText>{" "}</FormHelperText>
                    </FormControl>*/}

                    <UploadButton inputRef={fileInput}
                                  buttonTitle="파일을 선택하세요."
                                  previewName={fileName}
                                  onClick={handleBrowser}
                                  onInputChange={handleFileChange}
                                  error={error.file}
                                  errorText={errorText.file}/>
                </CardContent>

                <CardActions className={classes.action}>
                    <Button className={classes.button}
                            color="primary"
                            variant="outlined"
                            onClick={handleUpload}>
                        업로드
                    </Button>
                </CardActions>
            </Card>
        </>
    );
}