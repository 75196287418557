import React, {useState, useEffect} from 'react';

/** libs **/
import {connect} from "react-redux";

/** material **/
import {makeStyles} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

/** actions **/

/** components **/
import {ServerSettings, SchedulerSettings} from "components/Settings";

/** define **/
import Axios from "../../_define/Axios";


const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2)
    },

    snackBar: {
        background: theme.palette.primary.main
    }
}));

const Settings = ({uploading, onUploading, onAlert}) => {
    const classes = useStyles();
    const [tabValue, setTabValue] = useState(0);

    const [loaded, setLoaded] = useState(false);
    const [loadedError, setLoadedError] = useState(false);

    const [hostUrl, setHostUrl] = useState("");
    const [viewTerm, setViewTerm] = useState(30);
    const [hashKeyLength, setHashKeyLength] = useState(12);
    const [viewState, setViewState] = useState(1);
    const [uploadPath, setUploadPath] = useState("");
    const [isSchedulerLog, setIsSchedulerLog] = useState("0");
    const [schedulerLogCycle, setSchedulerLogCycle] = useState("* * * * *");
    const [isSchedulerView, setIsSchedulerView] = useState("0");
    const [schedulerViewCycle, setSchedulerViewCycle] = useState("* * * * *");
    const [schedulerViewDay, setSchedulerViewDay] = useState("1");

    useEffect(() => {
        if (!loaded && loadedError && uploading) {
            return;
        }

        setLoaded(false);
        onUploading(true);

        let cancel = false;
        Axios.get(`/api/settings`).then(({data}) => {
            const _data = data.data;

            setHostUrl(_data['host_url']);
            setViewTerm(_data['expried_term']);
            setHashKeyLength(_data['hash_key_length']);
            setViewState(_data['view_state']);
            setUploadPath(_data['upload_path']);

            setIsSchedulerLog(_data['scheduler_log_able']);
            setSchedulerLogCycle(_data['scheduler_log_cycle']);
            setIsSchedulerView(_data['scheduler_view_able']);
            setSchedulerViewCycle(_data['scheduler_view_cycle']);
            setSchedulerViewDay(_data['scheduler_view_day']);

            setLoaded(true);
            onUploading(false);
        }).catch((error) => {
            cancel = Axios.isCancel(error);
            if (!cancel) {
                setLoadedError(true);
                onUploading(false);
            }
        });
    }, []);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleChangeValue = (event, type) => {
        const newValue = event.target.value;
        switch (type) {

            case "hostUrl" :
                setHostUrl(newValue);
                break;
            case "viewTerm" :
                setViewTerm(newValue);
                break;
            case "hashKeyLength" :
                setHashKeyLength(newValue);
                break;
            case "viewState" :
                setViewState(newValue);
                break;
            case "uploadPath" :
                setUploadPath(newValue);
                break;
            case "isSchedulerLog" :
                setIsSchedulerLog(newValue);
                break;
            case "schedulerLogCycle" :
                setSchedulerLogCycle(newValue);
                break;
            case "isSchedulerView" :
                setIsSchedulerView(newValue);
                break;
            case "schedulerViewCycle" :
                setSchedulerViewCycle(newValue);
                break;
            case "schedulerViewDay" :
                setSchedulerViewDay(newValue);
                break;
        }
    };

    const handleUpdate = () => {
        if (loadedError && uploading) {
            return;
        }

        onUploading(true);

        Axios.post(`/api/settings/update`, {
            hostUrl: hostUrl,
            uploadPath: uploadPath,
            viewTerm: viewTerm,
            viewState: viewState,
            hashKeyLength: hashKeyLength,
            schedulerLogAble: isSchedulerLog,
            schedulerLogCycle: schedulerLogCycle,
            schedulerViewAble: isSchedulerView,
            schedulerViewCycle: schedulerViewCycle,
            schedulerViewDay: schedulerViewDay,
        }).then((data) => {
            onAlert("수정 되었습니다.");
            onUploading(false);
        }).catch((error) => {
            onAlert("에러가 발생 했습니다.");
            onUploading(false);
        });
    };

    return (
        <>
            <div className={classes.root}>
                <Tabs
                    value={tabValue}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleTabChange}>
                    <Tab label="서버 설정"/>
                    {/*<Tab label="스케쥴 설정"/>*/}
                </Tabs>

                <div>
                    {tabValue === 0 ? <ServerSettings hostUrl={hostUrl}
                                                      viewTerm={viewTerm}
                                                      hashKeyLength={hashKeyLength}
                                                      viewState={viewState}
                                                      uploadPath={uploadPath}
                                                      onChange={handleChangeValue}
                                                      onUpdate={handleUpdate} /> : null}
                    {/*{tabValue === 1 ? <SchedulerSettings isSchedulerLog={isSchedulerLog}
                                                         schedulerLogCycle={schedulerLogCycle}
                                                         isSchedulerView={isSchedulerView}
                                                         schedulerViewCycle={schedulerViewCycle}
                                                         schedulerViewDay={schedulerViewDay}
                                                         onChange={handleChangeValue}
                                                         onUpdate={handleUpdate}/> : null}*/}
                </div>
            </div>
        </>
    );
}

const mapStateToProps = ({auth}) => ({...auth});
export default connect(mapStateToProps)(Settings);

