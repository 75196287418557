import React, {useEffect, useState} from "react";

/** material **/
import {makeStyles} from '@material-ui/core/styles';
import {Select, MenuItem} from "@material-ui/core";

/** components **/
import {SearchInput} from 'components/Common';

const useStyles = makeStyles((theme) => ({
    root: {},
    row: {
        height: "42px",
        display: "flex",
        alignItems: "center",
        marginTop: theme.spacing(1)
    },
    searchSelect: {
        ...theme.typography.body2,
        padding: theme.spacing(0.5, 1),
        marginRight: theme.spacing(1),
        color: "#263238",
        backgroundColor: "#ffffff",
        boxShadow: "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
        borderRadius: "4px",

        "&:before": {
            border: "none !important"
        },

        "&:after": {
            border: "none !important"
        }
    },

    searchInput: {
        ...theme.typography.body2,
        padding: theme.spacing(0.5, 1),
        marginRight: theme.spacing(1)
    },

}));

export default function DocsToolbar({type, keyword, onSearch}) {
    const classes = useStyles();

    const [searchKeyword, setSearchKeyword] = useState(keyword);
    const [searchSelectValue, setSearchSelectValue] = useState(type);
    const [searchSelectOpen, setSearchSelectOpen] = useState(false);

    const handleSearchSelectClose = () => {
        setSearchSelectOpen(false);
    };

    const handleSearchSelectOpen = () => {
        setSearchSelectOpen(true);
    };

    const handleSearchSelectChange = (event) => {
        setSearchSelectValue(event.target.value);
    };

    const onChangeInput = (e) => {
        setSearchKeyword(e.target.value);
    };

    const onKeyPressInput = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
            onSearch(searchSelectValue, searchKeyword);
        }
    };

    useEffect(() => {
        if (type !== searchSelectValue) {
            setSearchSelectValue(type);
        }

        if (keyword !== searchKeyword) {
            setSearchKeyword(keyword);
        }
    }, [type, keyword]);

    return (
        <>
            <div className={classes.row}>
                <Select
                    className={classes.searchSelect}
                    open={searchSelectOpen}
                    onClose={handleSearchSelectClose}
                    onOpen={handleSearchSelectOpen}
                    value={searchSelectValue}
                    onChange={handleSearchSelectChange}>SearchInput
                    <MenuItem value={"id"}>뷰어 아이디</MenuItem>
                    <MenuItem value={"key"}>뷰어 키</MenuItem>
                </Select>

                <SearchInput
                    className={classes.searchInput}
                    value={searchKeyword}
                    onChange={onChangeInput}
                    onKeyPress={onKeyPressInput}
                />
            </div>
        </>
    );
}