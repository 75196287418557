import React, {useEffect, useState} from 'react';

import {LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend} from 'recharts';


/** material **/
import {makeStyles} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

/** action **/
import {setSnackBar, setUploading} from "../../actions/auth";

/** define **/
import Axios from "_define/Axios";

const useStyles = makeStyles((theme) => ({
    root: {},
    row: {
        height: "42px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        marginTop: theme.spacing(1),
        paddingRight: theme.spacing(3)
    },
}));

const DashboardViewer = ({dispatch}) => {
    const classes = useStyles();
    const [loaded, setLoaded] = useState(false);
    const [uploaded, setUploaded] = useState(true);

    const [data, setData] = useState([]);
    const [period, setPeriod] = useState("today");

    const handleChangeToData = (event) => {
        setPeriod(event.target.value);
        setLoaded(false);
    };

    useEffect(() => {
        if (loaded && uploaded) {
            return;
        }

        let cancel = false;
        let message = "";
        setUploaded(false);
        dispatch(setUploading(true));
        Axios.get(`/api/dashboard/viewer`, {
            params: {
                period: period
            }
        }).then(({data}) => {
            const _data = data.data;
            setData(_data);
        }).catch((error) => {
            cancel = Axios.isCancel(error);
            message = error.message
        }).then(function () {
            setLoaded(true);
            setUploaded(true);
            dispatch(setUploading(false));

            if(cancel){
                dispatch(setSnackBar({
                    status: true,
                    message: message
                }));
            }
        });
    });

    return (
        <>
            <div className={classes.row}>
                <FormControl variant="outlined" className={classes.textInput}>
                    <InputLabel htmlFor="dashboard-to-date">기간</InputLabel>
                    <Select
                        native
                        margin="dense"
                        label="기간"
                        value={period}
                        onChange={handleChangeToData}
                        inputProps={{
                            id: 'dashboard-to-date',
                        }}>
                        <option value="today">오늘</option>
                        <option value="week">일주일</option>
                        <option value="1month">1개월</option>
                        <option value="3month">3개월</option>
                        <option value="6month">6개월</option>
                        <option value="9month">9개월</option>
                        <option value="12month">12개월</option>
                    </Select>
                </FormControl>
            </div>

            <div style={{width: '100%', height: 300, fontSize: 12, marginTop: 20}}>
                <ResponsiveContainer>
                    <LineChart
                        width={500}
                        height={300}
                        data={data}
                        margin={{
                            top: 5, right: 30, left: 20, bottom: 5,
                        }}>
                        <XAxis dataKey="name"/>
                        <YAxis/>
                        <Tooltip/>
                        <Legend align="right" verticalAlign="top"/>
                        <Line name="열람 수" dataKey="hit" stroke="#8884d8"/>
                        <Line name="다운로드 수" dataKey="down" stroke="#82ca9d"/>
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </>
    );
};

export default DashboardViewer;