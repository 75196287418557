import React, {useEffect, useState} from "react";

/** material **/
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Axios from "../../../_define/Axios";

const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.secondary.main
    },

    action: {
        padding: theme.spacing(1),
        margin: theme.spacing(2),
    },
}));

export default function UserDeleteDialog({open, id = "", uploading, onUploading, onAlert, onClose}) {
    const classes = useStyles();
    const onDelete = () => {
        if (uploading) {
            return;
        }

        onUploading(true);

        Axios.post(`/api/auth/userDelete`, {
            id: id,
        }).then((data) => {
            onAlert("삭제 되었습니다.");
            onUploading(false);
            onClose();
        }).catch(({code}) => {
            onAlert("에러가 발생 했습니다.");
            onUploading(false);
        });
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"xs"}
            open={open}
            onClose={onClose}>
            <DialogTitle className={classes.title}>사용자 삭제</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    사용자를 삭제 하시겠습니까? 삭제 버튼을 누르면 사용자가 삭제 됩니다.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button className={classes.action}
                        color="secondary"
                        variant="outlined"
                        fullWidth
                        disabled={id === ""}
                        onClick={onDelete}>
                    삭제
                </Button>
            </DialogActions>
        </Dialog>
    );
};
