import React, {useEffect, useState} from 'react';

/** libs **/
import {connect} from "react-redux";

/** material **/
import {makeStyles} from '@material-ui/core/styles';

/** components **/
import {DocsToolbar, DocsTable} from "components/Docs";

/** define **/
import Axios from '_define/Axios';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    content: {
        marginTop: theme.spacing(2)
    }
}));

const Docs = (props) => {
    const {
        uploading,
        onUploading,
        onAlert,
    } = props;

    const classes = useStyles();
    const [loaded, setLoaded] = useState(false);
    const [loadedError, setLoadedError] = useState(false);
    const [reLoad, setReLoad] = useState(false);

    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalCnt, setTotalCnt] = useState(0);
    const [searchType, setSearchType] = useState("id");
    const [searchKeyWord, setSearchKeyWord] = useState("");

    const onLoad = () => {
        if (!loaded && loadedError && uploading) {
            return;
        }

        setLoaded(false);
        onUploading(true);

        let cancel = false;
        let keyword = searchKeyWord;
        if (keyword.length === 0) {
            keyword = null;
        }

        Axios.get(`/api/doc`, {
            params: {
                page: page,
                rowsPerPage: rowsPerPage,
                type: searchType,
                keyword: keyword,
            }
        }).then(({data}) => {
            const _data = data.data;
            setData(_data.rows);
            setTotalCnt(_data.totalCount);

            setLoaded(true);
            setReLoad(false);
            onUploading(false);
        }).catch((error) => {
            cancel = Axios.isCancel(error);
            if (!cancel) {
                setLoadedError(true);
                onUploading(false);
            }
        });
    };

    useEffect(() => {
        onLoad();
    }, []);

    useEffect(() => {
        if(reLoad){
            onLoad();
        }
    }, [reLoad]);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setReLoad(true);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
        setReLoad(true);
    };

    const handleOnSearch = (type, keyword) => {
        setSearchType(type);
        setSearchKeyWord(keyword);
        setReLoad(true);
    };

    const handleUpdateData = () => {
        setReLoad(true);
    };

    return (
        <div className={classes.root}>
            <DocsToolbar
                type={searchType}
                keyword={searchKeyWord}
                onSearch={handleOnSearch}
            />

            <div className={classes.content}>
                <DocsTable
                    {...props}
                    rows={data}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    totalCnt={totalCnt}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    handleUpdateData={handleUpdateData}
                />
            </div>
        </div>
    );
}

const mapStateToProps = ({auth}) => ({...auth});
export default connect(mapStateToProps)(Docs);

